import { css } from "aphrodite";
import PropTypes from "prop-types";

import { useStyles } from "hooks/useStyles";

import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  message: {
    color: "#555",
    background: "#f6f6f6",
    borderRadius: 5,
    padding: "1em 1.5em",
    fontSize: "0.875rem",
    lineHeight: 1.6,
    textAlign: "center",
    [ScreenSizes.mdAndBelow]: {
      display: "none",
    },
  },
  prominentMessage: {
    background: "#fff2da",
  },
};

const InfoMessage = (props) => {
  const { message, children, background, prominent } = props;
  const additionalStyles = {
    message: {
      ...(background && {
        background,
      }),
    },
  };
  const { styles } = useStyles([baseStyles, additionalStyles], props);

  return (
    <div className={css(styles.message, prominent && styles.prominentMessage)}>
      {message}
      {children}
    </div>
  );
};

InfoMessage.propTypes = {
  message: PropTypes.node,
  children: PropTypes.node,
  background: PropTypes.string,
  prominent: PropTypes.bool,
};
InfoMessage.defaultProps = {
  message: null,
  children: null,
  background: null,
  prominent: false,
};

export default InfoMessage;
